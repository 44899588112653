/* eslint-disable no-process-env */
import { isServerSide } from 'helpers/isServerSide';

export const IS_PRODUCTION_MODE = process.env.NODE_ENV === 'production';

const getEnv = (key) => {
	const value = isServerSide() ? process.env[key] : window.ENV?.[key];
	if (value === 'true') return true;
	if (value === 'false') return false;
	if (!isNaN(value) && value.trim() !== '') return Number(value);
	return value;
};

/**
 * This privateEnv vars aren't casted to boolean or number, they are always strings
 */
export const privateEnvConfig = Object.freeze({
	API_ROUTE_SECRET: process.env.API_ROUTE_SECRET,
	JWT_KEY: process.env.JWT_KEY,
	SPLIT_KEY: process.env.SPLIT_KEY,
});

/* These variables are meant to be public, no problem in exposing them to the browser
 * (This is something similar to nextjs NEXT_PUBLIC_*)
 */
const publicConfig = {
	browser: {
		BASE_URL: getEnv('BASE_URL'),
		BUGSNAG_BROWSER_API_KEY: getEnv('BUGSNAG_BROWSER_API_KEY'),
		BUGSNAG_ENABLED: getEnv('BUGSNAG_ENABLED'),
		GTM_ID: getEnv('GTM_ID'),
		IMAGE_BASE_URL: getEnv('IMAGE_BASE_URL'),
		INTERCOM_APP_ID: getEnv('INTERCOM_APP_ID'),
		MARKETING_SMS: getEnv('MARKETING_SMS'),
		NEXT_PUBLIC_NO_AXIOS_TIMEOUT: getEnv('NEXT_PUBLIC_NO_AXIOS_TIMEOUT'),
		OKTA_CLIENT_ID: getEnv('OKTA_CLIENT_ID'),
		OKTA_ISSUER: getEnv('OKTA_ISSUER'),
		PUSHER_APP_KEY: getEnv('PUSHER_APP_KEY'),
		PUSHER_ENABLED: getEnv('PUSHER_ENABLED'),
		RECAPTCHA_ENABLED: getEnv('RECAPTCHA_ENABLED'),
		RECAPTCHA_V2_SITE_KEY: getEnv('RECAPTCHA_V2_SITE_KEY'),
		RELATIVE_BASE_URL: getEnv('RELATIVE_BASE_URL'),
		RELEASE_STAGE: getEnv('RELEASE_STAGE'),
		RESTURL_BASE: getEnv('RESTURL_BASE'),
		SEGMENT_WRITE_KEY: getEnv('SEGMENT_WRITE_KEY'),
		SOCKET_IO_URL: getEnv('SOCKET_IO_URL'),
		STOCKWAVE_SCRIPT_URL: getEnv('STOCKWAVE_SCRIPT_URL'),
		VERSION: getEnv('VERSION'),
	},
	server: {
		BUGSNAG_SERVER_API_KEY: getEnv('BUGSNAG_SERVER_API_KEY'),
		NEXT_RUNTIME: getEnv('NEXT_RUNTIME'),
		NODE_ENV: getEnv('NODE_ENV'),
		PAPERTRAIL_ENABLED: getEnv('PAPERTRAIL_ENABLED'),
		PAPERTRAIL_HOST: getEnv('PAPERTRAIL_HOST'),
		PAPERTRAIL_PORT: getEnv('PAPERTRAIL_PORT'),
		STATIC_PROPS_REVALIDATE_TIME: getEnv('STATIC_PROPS_REVALIDATE_TIME'),
		STORYBOOK: getEnv('STORYBOOK'),
	},
};

export const publicEnvConfig = Object.freeze({
	...publicConfig.browser,
	...publicConfig.server,
});

export const getBrowserEnvVariables = () => {
	const envVariables = Object.freeze({
		...Object.keys(publicConfig.browser).reduce((acc, key) => {
			acc[key] = process.env[key];
			return acc;
		}, {}),
	});

	return `window.ENV = ${JSON.stringify(envVariables)};`;
};
