import { BID_SALE_SOURCE_TAB } from 'constants/bidSale';
import { getMarketplaceSourceTabFromTabName, MARKETPLACE_TAB } from 'constants/marketplace';
import { getVdpURL } from 'helpers/externalRoutes';
import { LIST_KEYS } from 'store/slices/buyerVehicles/helpers/vehicles';

export function buildParamsURL(params, defaultFilters, addDefaults = true) {
	let result = [];
	for (var par in params) {
		if (params.hasOwnProperty(par) && params[par] !== undefined && params[par] !== null && params[par] !== '') {
			if (addDefaults || params[par] !== defaultFilters?.[par]) {
				result.push(encodeURIComponent(par) + '=' + encodeURIComponent(params[par]));
			}
		}
	}
	return result.join('&');
}

export const checkboxIsChecked = (params, prop, slug, checkIfThereIsChildSelected) => {
	const list = params[prop] && typeof params[prop] === 'string' ? params[prop].split(',') : params[prop];

	if (checkIfThereIsChildSelected) {
		// Easy way to check if there is a child selected without loading the children first
		const isChildChecked =
			list &&
			list?.some((value) =>
				typeof value === 'string' ? value.toLowerCase().startsWith(`${slug}_`.toLowerCase()) : value,
			);
		if (isChildChecked) {
			return true;
		}
	}

	return Boolean(
		list &&
			list.some((value) =>
				typeof value === 'string' ? value.toLowerCase() === slug.toLowerCase() : value === slug,
			),
	);
};

export function buildURLForVehicleRedirection({ vehicle, params, refUrl, generateUrlLocally, ...rest }) {
	const paramsUrl = buildParamsURL({ ...params, ref_url: refUrl, ...rest });
	const url =
		Boolean(vehicle.branch_url && !generateUrlLocally) ? `${vehicle.branch_url}` : `${getVdpURL(vehicle.id)}`;
	return `${url}${paramsUrl ? `?${paramsUrl}` : ''}`;
}

const isEmptyFilter = (value) =>
	value === undefined || value === null || value === '' || (Array.isArray(value) && !value.length);

function bothAreEmptyOrNullable(defaultValue, currentValue) {
	return isEmptyFilter(defaultValue) && isEmptyFilter(currentValue);
}

export function areFiltersModified(defaultFilters, params) {
	return Object.entries(defaultFilters).some(
		([key, defaultValue]) =>
			params[key] !== undefined &&
			!bothAreEmptyOrNullable(defaultValue, params[key]) &&
			JSON.stringify(params[key]) !== JSON.stringify(defaultValue),
	);
}

export const acceptedQueryParams = {
	tab: {
		type: 'stringFromList',
		possible_values: [...Object.values(MARKETPLACE_TAB), ...Object.values(BID_SALE_SOURCE_TAB)],
	},
};

// remove when tbd_187929_unified_marketplace_react is fully enabled
export const acceptedQueryParamsForUnifiedMarketplace = {
	tab: {
		type: 'stringFromList',
		possible_values: Object.values(MARKETPLACE_TAB),
	},
};

export const sanitizeStringFromList = (key, value, params) =>
	params[key]?.possible_values?.find((possibleValue) => possibleValue === value);

export const CONTEXT = {
	auction: 'auction',
	marketplace: 'marketplace',
};

export const getSaveSearchContext = (isAuction) => (isAuction ? CONTEXT.auction : CONTEXT.marketplace);

const sourceTabFromSearchListKeyMap = {
	auction: 'search',
	hidden: 'hidden',
	offers: 'offers',
	watchlist: 'watchlist',
};

export const getSourceTabFromSearchListKey = (searchListKey) =>
	sourceTabFromSearchListKeyMap[searchListKey] || getMarketplaceSourceTabFromTabName(searchListKey);

export const getListContextFromSearchListKey = (searchListKey) =>
	getMarketplaceSourceTabFromTabName(searchListKey) ? CONTEXT.marketplace : CONTEXT.auction;

const CONTEXT_DEFAULT_LIST_KEY = {
	[CONTEXT.auction]: LIST_KEYS.auction,
	[CONTEXT.marketplace]: MARKETPLACE_TAB.marketplace,
};

export const getDefaultSearchListKeyForContext = (contextName) => CONTEXT_DEFAULT_LIST_KEY[contextName];
